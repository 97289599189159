<template>
  <div class="verification-phone pb-14">
    <v-row>
      <v-col cols="12" md="5" class="text-center">
        <img
          width="60%"
          :src="require('@/assets/images/verification/paypal.png')"
          alt=""
        />
      </v-col>
      <v-col cols="12" md="5">
        <v-form v-model="valid">
          <div :class="$vuetify.breakpoint.mobile ? 'text-center' : ''">
            <span class="d-block font-30 font-500 primary--text"
              >{{$t('pleaseEnterYourPaypalAccount')}}</span
            >
            <base-input
              class="mt-2 mb-6"
              v-model="account"
              :rules="$helper.emailRules"
            ></base-input>
            <v-btn
              class="c-btn mt-10"
              color="secondary"
              min-width="240"
              height="40"
              @click="nextHandle"
              :disabled="!valid"
              ><span class="white--text font-300 font-20">{{$t('next')}}</span></v-btn
            >
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    account: "",
    valid: false,
  }),
  methods: {
    nextHandle() {
      this.$emit("submit", this.account);
    },
  },
};
</script>

<style></style>
