<template>
  <div class="verification-phone pb-14">
    <v-row>
      <v-col cols="12" md="5" class="text-center">
        <img
          width="60%"
          :src="require('@/assets/images/verification/phone-in-hand.png')"
          alt=""
        />
      </v-col>
      <v-col cols="12" md="7">
        <div class="text-center">
          <span class="d-block font-30 font-500 primary--text"
            >{{$t('weHaveSentCode')}}</span
          >
                    <!-- <span class="d-block font-30 font-500 primary--text"
            >We have sent the code verification to your mobile number</span
          > -->
          <CodeInput
            :loading="false"
            class="input mx-auto"
            v-on:complete="onComplete"
            :fields="6"
          />
          <div class="text-center mt-14">
            <v-btn
              class="c-btn mx-1"
              color="grey"
              height="45"
              min-width="200"
              @click="$emit('cancel')"
              ><span class="white--text font-20 font-300">{{$t('cancel')}}</span></v-btn
            >
            <v-btn
              class="c-btn mx-1"
              color="secondary"
              min-width="240"
              height="40"
              @click="verifyCode"
              ><span class="white--text font-300 font-20">{{$t('next')}}</span></v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {
    onComplete(v) {
      this.otpnum = v;
    },
    verifyCode() {
      if (localStorage.getItem("verifidNumber")) {
        this.$emit("next", "code");
      } else {
        window.confirmResult
          .confirm(this.otpnum)
          .then(() => {
            localStorage.setItem("verifidNumber", true);
            this.$emit("next", "code");
          })
          .catch(() => {
            this.$store.dispatch("showSnack", {
              text: "invalid code please check",
              color: "error",
            });
          });
      }
    },
    nextHandle() {},
  },
};
</script>

<style lang="scss">
.react-code-input {
  input {
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 4px solid #b5b5b5 !important;
    margin-right: 15px;
    font-size: 30px !important;
    font-weight: 600 !important;
  }
}
.react-code-input-container {
  width: max-content !important;
}
</style>
