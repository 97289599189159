<template>
  <div class="verification-page">
    <section-head
      firstTitle="Verification"
      :img="require('@/assets/images/head/trip.jpg')"
    ></section-head>
    <transition name="fade" mode="out-in">
      <!-- main step -->
      <div class="text-center mt-10" v-if="!contentAvailable">
        <img :src="require('@/assets/images/verification/person.png')" alt="" />
        <span class="d-block font-30 font-700 primary--text"
          >{{$t('areYouTraveler')}}?</span
        >
        <p class="font-25 font-500 black--text mt-4">
          {{$t('toAddATrip')}}
        </p>
        <!-- <p class="font-25 font-500 black--text mt-4">
          To add a Trip, you must confirm that you are traveler
        </p> -->

        <v-btn
          class="c-btn mt-8"
          color="secondary"
          min-width="240"
          height="40"
          @click="contentAvailable = true"
          ><span class="white--text font-300 font-20">{{$t('verification')}}</span></v-btn
        >
      </div>

      <!-- content -->
      <div class="max-width" v-if="contentAvailable">
        <v-stepper class="mt-14" v-model="step">
          <!-- steps head -->
          <v-stepper-header class="white">
            <v-stepper-step :complete="step > 1" step="1">
              <span class="font-22"> {{$t('phoneNumber')}}</span>
            </v-stepper-step>

            <v-stepper-step :complete="step > 2" step="2">
              <span class="font-22"> {{$t('code')}}</span>
            </v-stepper-step>

            <v-stepper-step :complete="step > 3" step="3">
              <span class="font-22"> {{$t('passport')}}</span>
            </v-stepper-step>
            <v-stepper-step step="4">
              <span class="font-22"> {{$t('paypal')}}</span>
            </v-stepper-step>
          </v-stepper-header>

          <div class="verification-page__content mt-14">
            <!-- steps content -->
            <v-stepper-items>
              <!-- phone step -->
              <v-stepper-content step="1"
                ><phone-step @next="handleStep($event, 'phone')"></phone-step>
              </v-stepper-content>

              <!-- verify step -->
              <v-stepper-content step="2"
                ><code-step
                  @next="handleStep($event, 'code')"
                  @cancel="step = 1"
                ></code-step>
              </v-stepper-content>

              <!-- passport step -->
              <v-stepper-content step="3"
                ><passport-step
                  @next="handleStep($event, 'passport')"
                  @cancel="step = 2"
                ></passport-step>
              </v-stepper-content>

              <!-- paypal step -->
              <v-stepper-content step="4"
                ><paypal-step
                  @submit="handleStep($event, 'paypal')"
                  @cancel="step = 3"
                ></paypal-step>
              </v-stepper-content>
            </v-stepper-items>
          </div>
        </v-stepper>
      </div>
    </transition>

    <!-- verify dialog -->
    <v-dialog v-model="verifyDialog" max-width="400" content-class="white">
      <verify-dialog @close="closeHandler"></verify-dialog>
    </v-dialog>
  </div>
</template>

<script>
import PhoneStep from "./components/Phone.vue";
import CodeStep from "./components/Code.vue";
import PassportStep from "./components/Passport.vue";
import PaypalStep from "./components/Paypal.vue";
import VerifyDialog from "@/components/dialogs/Verify";
import SectionHead from "../../components/core/SectionHead.vue";
export default {
  components: {
    PhoneStep,
    CodeStep,
    PassportStep,
    PaypalStep,
    VerifyDialog,
    SectionHead,
  },
  data: () => ({
    step: 1,
    verifyDialog: false,
    contentAvailable: false,
    obj: {},
  }),
  methods: {
    handleStep(value, type) {
      if (type == "phone") {
        this.obj.phone = value.phone;
        this.obj.country_code = value.code;
        this.step = 2;
      }
      if (type == "code") {
        console.log("verify");
        this.step = 3;
      }
      if (type == "passport") {
        this.obj.passport = value;
        this.step = 4;
      }
      if (type == "paypal") {
        this.obj.email_paypal = value;
        this.submitHandler();
      }
    },
    async submitHandler() {
      let formData = new FormData();
      formData.append("country_code", this.obj.country_code);
      formData.append("phone", this.obj.phone);
      formData.append("passport", this.obj.passport);
      formData.append("email_paypal", this.obj.email_paypal);
      const { data } = await this.$axios.post(
        "/sendRequestAsBringer",
        formData
      );
      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "success",
        });
        this.verifyDialog = true;
      } else {
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "error",
        });
      }
    },
    closeHandler() {
      this.contentAvailable = false;
      this.verifyDialog = false;
      this.step = 1;
    },
  },
  beforeRouteEnter(_, _2, next) {
    if (
      localStorage.getItem("role") != "bringer" &&
      localStorage.getItem("role") == "sender"
    ) {
      next();
    } else {
      next("/sign-in");
    }
  },
};
</script>

<style lang="scss">
.verification-page {
  .v-stepper {
    background: transparent !important;
    box-shadow: none !important;

    &__header {
      width: 80%;
      margin: auto;
      border-radius: 50px;
      height: 50px;
      padding: 0px 30px !important;
    }
    &__step {
      padding: 8px 20px;
    }
  }

  &__content {
    max-width: 1300px !important;
    margin: auto;
  }
}
</style>
