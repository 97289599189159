<template>
  <div class="verification-phone pb-14">
    <v-row>
      <v-col cols="12" md="5" class="text-center">
        <img
          width="60%"
          :src="require('@/assets/images/verification/passport.png')"
          alt=""
        />
      </v-col>
      <v-col cols="12" md="5">
        <div :class="$vuetify.breakpoint.mobile ? 'text-center' : ''">
          <span class="d-block font-30 font-500 primary--text"
            >{{$t('pleaseUploadYourPassport')}}</span
          >
          <div
            class="upload-cover d-flex align-center justify-space-between px-2 mt-2"
          >
            <div class="upload-text curosr-pointer d-inline-block ml-3">
              <v-btn class="c-btn" color="purple" elevation="0"
                ><span class="white--text font-15 font-500">Upload</span></v-btn
              >
              <v-file-input
                class="upload-text__btn"
                hide-input
                truncate-length="15"
                @change="inputChange"
              ></v-file-input>
            </div>
          </div>
          <div class="d-flex align-center justify-space-between">
            <span class="font-20 purple--text d-block mt-2">{{
              file.name
            }}</span>
            <v-icon
              @click="file = {}"
              v-if="file.name"
              color="primary"
              size="20"
              >mdi-close</v-icon
            >
          </div>
          <div class="text-center mt-14">
            <v-btn
              class="c-btn mx-1"
              color="grey"
              height="45"
              min-width="200"
              @click="$emit('cancel')"
              ><span class="white--text font-20 font-300">{{$t('cancel')}}</span></v-btn
            >
            <v-btn
              class="c-btn mx-1"
              color="secondary"
              min-width="240"
              height="40"
              @click="nextHandle"
              :disabled="!file.name"
              ><span class="white--text font-300 font-20">{{$t('next')}}</span></v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    file: {},
  }),
  methods: {
    inputChange(file) {
      this.file = file;
    },
    nextHandle() {
      this.$emit("next", this.file);
    },
  },
};
</script>

<style></style>
