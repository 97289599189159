<template>
  <!-- head -->
  <div class="verification-page">
    <div class="max-width">
      <div class="verification-page__head mt-12">
        <img class="verification-page__head__bg object-cover" :src="img" alt="" />
        <div class="verification-page__head__layout" v-if="firstTitle"></div>
        <div class="verification-page__head__title w-100">
          <div class="d-flex align-center justify-center">
            <span class="font-40 font-500 white--text">{{ firstTitle }}</span>
            <img
              class="mx-2"
              :src="require('@/assets/images/icons/arrow-right.png')"
              width="8px"
              height="17px"
              alt=""
              v-if="secondTitle"
            />
            <span class="font-40 font-500 white--text">{{ secondTitle }}</span>

            <div class="d-flex align-center" v-if="thirdTitle">
              <img
                class="mx-2"
                :src="require('@/assets/images/icons/arrow-right.png')"
                width="8px"
                height="17px"
                alt=""
              />
              <span class="font-40 font-500 white--text">{{ thirdTitle }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    firstTitle: { type: String, default: "" },
    secondTitle: { type: String, default: "" },
    thirdTitle: { type: String, default: "" },
    img: { type: String, default: "" },
  },
};
</script>

<style lang="scss" scoped>
.verification-page {
  &__head {
    position: relative;
    height: 350px;
    border-radius: 10px;
    overflow: hidden;
    &__bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
    }

    &__layout {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: #6c6c6c59;
      z-index: 2;
    }

    &__title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
    }
  }
}
</style>
